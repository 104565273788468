import Choices from 'choices.js';

function autocomplete(elementId, fetchUrl, labelKey, options = undefined) {
  let element = document.getElementById(elementId);
  let search;
  if (element !== null) {
    const defaultOptions = {
      searchFloor: 0,
      searchResultLimit: 99,
      removeItemButton: true,
      allowHTML: true,
      placeholder: true,
      placeholderValue: 'Click to select an item',
      searchPlaceholderValue: 'Type to start searching'
    };
    let choices = new Choices(element, options || defaultOptions);

    element.addEventListener('showDropdown', function(e) {
      fetch(fetchUrl).then(function(response) {
        if (response.ok) {
          let promise = response.json().then(function(data) {
            choices.setChoices(data, 'id', labelKey, true);
          }).catch(e => {
            console.log(e);
          });
          ;
        }
      });
    });

    element.addEventListener('search', function(e) {

      search = e.detail.value;

      fetch(fetchUrl + search).then(function(response) {
        if (response.ok) {
          let promise = response.json().then(function(data) {
            choices.setChoices(data, 'id', labelKey, true);
          }).catch(e => {
            console.log(e);
          });
          ;
        }
      });
    });
  }
}

function autocompleteEan(elementId, fetchUrl, labelKey, options = undefined) {
  let elementEan = document.getElementById(elementId);

  if (elementEan !== null) {
    const defaultOptions = {
      searchFloor: 2,
      searchResultLimit: 99,
      removeItemButton: true,
      allowHTML: true,
      addItems: true
    };


    let choicesEAN = new Choices(elementEan, options || defaultOptions);

    elementEan.addEventListener('change', function(e) {
      console.log('changing.....');
    });

    elementEan.addEventListener('search', function(e) {
      console.log('searching..');
      const search = e.detail.value;

      fetch(fetchUrl + search).then(function(response) {
        if (response.ok) {
          let promise = response.json().then(function(data) {
            choicesEAN.setChoices(data, 'id', labelKey, true);
          });
        }
      });
    });
  }
}

function initBrandChoices() {
  autocomplete('id_brands', '/api/products/brands?limit=99&search=', 'name');
  autocomplete('id_brand', '/api/products/brands?limit=99&search=', 'name');
  autocomplete('id_products', '/api/products?limit=99&search=', 'name');
  autocomplete(
    'id_default_brand',
    '/api/products/brands?limit=99&search=',
    'name'
  );
  autocomplete('id_supplier', '/api/suppliers?limit=99&search=', 'name');
  autocomplete('id_contact', '/api/suppliers?limit=99&search=', 'name');
  autocomplete('id_compare_to', '/api/suppliers?limit=99&search=', 'name');
}

window.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('htmx:load', () => {
    autocompleteEan('id_product', '/api/products?limit=99&search=', 'name');
    autocompleteProduct('id_product_add', '/api/products?limit=99&search=', 'name');
    initBrandChoices();
  });
});


function autocompleteProduct(elementId, fetchUrl, labelKey, options = undefined) {
  let element = document.getElementById(elementId);
  let search;
  if (element !== null) {
    const defaultOptions = {
      searchFloor: 2,
      searchResultLimit: 99,
      removeItemButton: true,
      allowHTML: true,
      noResultsText: function() {
        console.log('search', search);
        return '<a href="/suppliers/products/create-full?ean=' + search + '" hx-boost="true" hx-target="#main" class="btn btn-danger">Add product</a>';
      }
    };
    let choices = new Choices(element, options || defaultOptions);

    element.addEventListener('search', function(e) {

      search = e.detail.value;

      fetch(fetchUrl + search).then(function(response) {
        if (response.ok) {
          let promise = response.json().then(function(data) {
            choices.setChoices(data, 'id', labelKey, true);
          }).catch(e => {
            console.log(e);
          });
          ;
        }
      });
    });
  }
}
